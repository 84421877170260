@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


.spinner_overlay {
  background-color: #000;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
  overflow-y: hidden;
}

.spinner-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 12;
}

.loading-icon {
  display: flex;
  margin: auto;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  position: relative;
}



.additonal-sheet {
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  max-height: 170px;
  display: flex !important;
  align-items: center;
  background: #151515;
}

.additonal-sheet img {
  height: 170px;
  margin: 0 auto;
}


.error {
  color: red;
  font-size: 14px;

}

.upload-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.image_msg {
  font-size: 14px;
  background-color: #fff3f2;
  color: #f96453;
  border-radius: 4px;
  border: 1px solid #f96453;
  max-width: 90px;
  padding: 5px;
  height: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 15px !important;
  flex: 0 0 105px;
  margin-right: 8px;
}

.image_msg i {
  display: block;
  font-size: 30px;
  margin-bottom: 6px;
}

.image_msg span {
  position: absolute;
  top: 0px;
  right: 7px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.image_msg label {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading img {
  width: 150px;
  height: auto;
  background-color: #ffffff;
  padding: 10px 21px;
  border-radius: 10px;
}

.active_like {
  background: green;
  border-radius: 50%;
}

.active_dislike {
  background: #ff7070;
  border-radius: 50%;
}

.eUeawN .wrapper {
  z-index: 10 !important;
}

.react-tel-input .form-control {
  border: 1px solid #ddd !important;
  width: 100% !important;
  height: 100% !important;
  padding: 11px 14px 11px 60px !important;
}

.react-tel-input .country-list .search-box {
  min-width: 92% !important;
}

.modal-footer {
  padding: 1rem;
}

.modal-body {
  padding: 1rem;
}

.modal-header {
  padding: 1rem;
  padding-top: 26px;
  padding-bottom: 0;
}

.modal-header .modal-title {
  font-weight: 600;
}

.bannersetimg {
  width: 100%;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannersetimg img,
.scrollimgs img {
height: 40px;
  object-fit: cover;
}

.scrollimgs img {
  border-radius: 10px;
}


.scrollimgs {
  min-width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.logout svg {
  width: 80px;
  height: 80px;
  display: block;
  margin: auto;
}

.logout svg path {
  fill: #f44336;
}

.invite_btn a {
  padding-left: 20px;
  padding-right: 20px;
}

.mobilemenubottom a.active {
  color: #7fbfbf;
}

.mobilemenubottom a.active svg path,
.mobilemenubottom a.active svg {
  fill: #7fbfbf;
}

.red_btn {
  background-color: #F10202;
  border-color: #F10202;
}

.red-color {
  color: #F10202;
}

.switch-account {
  color: #007d7b;
  font-weight: bold;
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 20px;
  padding: 8px;
}

.selected>.menuCard {
  background-color: #ddd;
}

.joining-soon {
  margin-top: 60px;
}

.font_large3 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.font-colosrr {
  color: #000;
  font-weight: 700;
  text-decoration: underline !important;
}

/* date - 11-11-2024 */
.mymenu {
  background: #FFEAF0;
  border: none;
  border-radius: 8px;
  padding: 7px 9px;
}

.banner-image-container {
  position: relative;
  width: 100%;
  height: 243px;
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: center;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  padding: 20px;
}

.smallbanner-image-container {
  position: relative;
  width: 100%;
  height: 154px;
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  color: #fff;

  border-radius: 12px;
  padding: 20px;
}

.background-image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.banner-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(182.35deg, rgb(255 255 255 / 22%) 35.96%, rgb(197, 25, 68) 76.8%, #C51944 102.03%);
  z-index: 1;
}

.smallbanner-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #c51944b8 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));



  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 2;
}

.banner-content h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;



}

.banner-content p {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;



}


.smallbanner-content {
  position: relative;
  z-index: 2;
}

.smallbanner-content h2 {
  font-family: "Work Sans", sans-serif;

  font-size: 22.1px;
  font-weight: 600;

}

.smallbanner-content p {
  font-family: "Work Sans", sans-serif;

  font-size: 16.58px;
  font-weight: 400;
  line-height: 19.44px;
}


.mainheadingnew {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;


  color: #3E4949;



}

.resto-list {
  margin-top: 38px;
}

.mysearch {
  border-radius: 10px;
}

.jiMOeR .wrapper {
  border-radius: 8px !important;
}

.dYrfwz>input {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #526060;
}

.restrocard {
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px #9F9F9F40;
  padding: 12px;

}

.dishcontnent h6 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.77px;

  color: #526060;

}

.starbutton {
  background: #E2F9F9;
  padding: 5px;
  color: #007d7b;
  width: 42px;
  height: 27px;
  border-radius: 8px;
}

.likebtn {
  background: rgba(255, 234, 235, 1);
  border-radius: 8px;
  padding: 5px;

  width: 42px;
  height: 27px;
}

.starbutton span {
  color: #007c7c;
}

.likebtn span {
  color: rgb(0, 0, 139);

}

.parallel {
  position: unset !important;

}

.detailheader {

  /* margin-top: 10px; */
}

.newheaderdetail {
  padding: 10px 19px;

}

.newheaderdetail2 {

  padding: 17px 19px;
}

.backarrownew figure {
  border: none;
  box-shadow: unset;
  background: rgba(255, 234, 240, 1);
  border-radius: 7px;
}

.bannersetimgnew {
  height: unset;
}

.outerbanner {
  height: 168px;
}

.outerbanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.detailpageheadcontent h6 {
  font-family: "Work Sans", sans-serif;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;


}

.detailloc {
  width: 15px;
}

.dishcontnenttwo .newlikedislike {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  border-radius: 6px;
  border: none;
  background: unset;
  box-shadow: unset;
  margin: 0px 0px 0px 0px;
}

.detailstarsec {
  background: rgba(226, 249, 249, 1);
  border: 1px solid rgba(11, 146, 149, 0.1);
  border-radius: 8px;
  padding: 8px;
  width: 50%;
  justify-content: space-between;
}

.detaillikesec {
  background: rgba(255, 234, 235, 1);
  width: 50%;
  border: 1px solid rgba(229, 27, 36, 0.1);
  border-radius: 8px;
  padding: 8px;
  justify-content: space-between;
}

.detailstarsec h4 {
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.77px;

  color: rgba(11, 146, 149, 1);


}

.detaillikesec h4 {
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.77px;

  color: rgba(229, 27, 36, 1);


}

.rightstarsec img {
  width: 28px !important;

}

.rightstarsec span {
  font-family: "Work Sans", sans-serif;
  font-size: 32px;
  font-weight: 500;

  text-align: center;

  color: #007c7c;

}

.detaillikesec img {
  width: 28px !important;

}

.detaillikesec span {
  font-family: "Work Sans", sans-serif;
  font-size: 32px;
  font-weight: 500;

  text-align: center;


  color: rgba(229, 27, 36, 1);

}

.restdetailcard {
  border: 1px solid rgba(34, 46, 46, 0.1)
}


.scroll-image-with-text{
  height: 80px;
  padding: 6px 6px 0px 6px;
  margin-bottom: 6px;
  margin-top: 8px;
  box-shadow: 0px 2px 12px 0px rgba(143, 143, 143, 0.25);
  margin-right: 8px;
  text-align: center;
}
.newcontenttwop {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
}

.newpizzaaher.selected {
  font-family: "Work Sans", sans-serif;
  color: rgba(11, 146, 149, 1);
  font-size: 16px;
  background: rgba(11, 146, 149, 0.1);

  border: 1px solid rgba(11, 146, 149, 0.1);
  border-radius: 100px;
}

.newpizzaaher {
  font-family: "Work Sans", sans-serif;
  color: rgba(62, 73, 73, 0.5);

  font-size: 16px;
  background: rgba(228, 248, 248, 0.205);
  padding: 4px 11px;
  border: none;
  border-radius: 100px;
  flex: 0 0 auto;
}

.newpizzaaherclear {
  font-family: "Work Sans", sans-serif;
  color: rgba(11, 146, 149, 1);
  font-size: 16px;
  background: rgba(11, 146, 149, 0.1);

  border: 1px solid rgba(11, 146, 149, 0.1);
  border-radius: 100px;
}

.newdisdcarditems {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);

}

.logoimg {
  margin-left: 26px;
}

.backarrownew figure {
  border: none;
  box-shadow: unset;
  background: rgba(255, 234, 240, 1);
  border-radius: 7px;
  /* padding: 10px; */
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonlogoimg {
  margin-left: 43px;
  width: 102px;
  height: 39px;
}

.newheadingone {
  font-family: "Work Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;

  text-align: center;


  color: rgba(62, 73, 73, 1);

}

.newmobcardbox {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 26px 0;
}

.newmobcardbox h2 {

  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;


  text-align: center;


}

.nolike {
  color: rgba(197, 25, 68, 1) !important;
}

.yeslike {
  color: rgba(11, 149, 74, 1) !important;
  ;
}

.newmobcontainer {
  align-items: unset !important;
}

.newbodycont {
  background: url(/public/image/top-bg.png) no-repeat top center / contain;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}

.backarrow {
  z-index: 2;
}

.newcheckitem {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  padding: 8px;
  border-radius: 10px;
  border: 1px solid rgba(248, 252, 252, 1);
}

.newcheckitem .form-check-label {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;




  color: rgba(62, 73, 73, 1);
  margin-left: 11px;
}

.reviewitem {
  width: 100%;
}

.newcheckitem .form-check-input:checked[type="checkbox"] {
  background-image: url(/public/image/newtick.png);
  background-repeat: no-repeat;
  background-color: rgba(11, 146, 149, 1);
  border-radius: 50%;
  background-size: 14px 11px;
}

.newcheckitem .form-check-input {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-color: #c6c6c6;
}

.newbtnmain {
  border: none;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(11, 146, 149, 1);
}

.newbtnmainlight {
  border: none;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  color: black;
  border: 1px solid rgba(248, 252, 252, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}
.newbtnmainlightnew {
  border: none;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  color: black;
  border: 1px solid rgba(248, 252, 252, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}



.newsmallheadingone {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;

  text-align: center;


  color: rgba(62, 73, 73, 1);
}

.newformarea {
  box-shadow: 0px 23.1px 46.2px 0px rgba(0, 0, 0, 0.102);

  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  color: rgba(82, 96, 96, 1);

}

.newwhitebtnmain {
  border: none;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 4px 12px 0px rgba(164, 164, 164, 0.25);
  border: 1px solid rgba(11, 146, 149, 0.1);
  background-color: rgb(255, 255, 255);
  color: #007c7c;
}

.newwhitebtnmain:hover {
  color: #fff;
}


/* tab design */
@media (min-width: 767px) and (max-width: 1025px) {
  .newqr-code {
    position: unset !important;

    width: 100%;
    height: auto;
  }
}


.cardratinghead {
  background-color: rgba(11, 146, 149, 1);
  color: #fff;
  padding: 15px;
  border-radius: 18px 18px 0px 0px;
  position: relative;

}

.cardratingheadnew {
  background-color: rgba(11, 146, 149, 1);
  color: #fff;
  padding: 15px;
  border-radius: 18px 18px 0px 0px;
  position: relative;
  height: 80px;
  overflow: hidden;
}

.newcardfooter {
  border-radius: 0px 0px 18px 18px !important;
}

.newcardrating {
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.25);


  border-radius: 18px;
  border: 1px 0px 0px 0px;


}

.newcardrating h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.cardstar {
  background-color: rgba(226, 249, 249, 1);
  padding: 9px;
  border-radius: 10px;
  gap: 9px;
}

.cardstar img {
  width: 32px;
}

.cardlike {
  background-color: rgba(255, 234, 235, 1);
  padding: 9px;
  border-radius: 10px;
  gap: 9px;
}

.cardlike img {
  width: 32px;
}

.cardmiddle {
  height: 408px;
  overflow-y: auto;
  scrollbar-width: none;
}
.cradheight{
  height: 475px;
}

.cardmiddlecontent {
  border-bottom: 1px solid rgba(11, 146, 149, 0.2);
  padding: 5px 14px;
}

.cardmiddlecontent label {
  font-family: "Work Sans", sans-serif;
  font-size: 23px !important;
  font-weight: 500 !important;
  line-height: 32.84px;

  color: rgba(62, 73, 73, 1);
}

.cardmiddlecontent p {
  font-family: "Work Sans", sans-serif;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 49.27px;

  text-align: center;


  color: rgba(62, 73, 73, 1);
}

.cardstar h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;

  text-align: center;


  color: rgba(11, 146, 149, 1);
}

.cardlike h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;

  text-align: center;


  color: rgb(0, 0, 139);
}

.newvidbannersec figure {
  height: unset !important;
  width: 100%;

}

.dummy-screen-two .newqr-code img {
  max-width: 100%;
}

.menucontentsec {
  width: 100%;
}

/* .restlogo{
  width: 50%;
} */
/* .newqrcode{
  width: 50%;
} */
@media (min-width: 767px) and (max-width: 1025px) {
  .dummy-screen .vidbannersec figure {
    min-height: auto !important;
  }
}

.newvidbannersec {
  border: 1px solid rgba(62, 73, 73, 0.4);
  padding: 15px;
  width: 100%;
  border-radius: 18px;
}

.newimage-content h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 30.5px;

  color: rgba(62, 73, 73, 1);
}

.newimage-content span {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;


  color: rgba(62, 73, 73, 1);
}

.newimage-content p {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  color: rgba(62, 73, 73, 1);
}

.restimageouterdiv {
  height: 272px;
  position: relative;
}
.restimageouterdiv .highlight-badge{
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px !important;
  background-color: #00000061;
  color: #fff;
  font-weight: 400;
  padding: 8px 20px;
  border-radius: 20px;
}
.restimageouterdiv .Rank-leaderboard{
  position: absolute;
  top: -5px;
  right: -1px;
  width: 60px;
  height: 60px;
}
.recently-reviewed {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px !important;
  background-color: #ffffff;
  color: rgb(0, 0, 0) !important;
  font-weight: 400;
  padding: 5px 20px 5px 10px;
  border-radius: 20px;
}
.recently-reviewed span img{
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.restimageouterdiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}

.vidbannersec figure img {
  border-radius: 18px;
}



.billcard {
  background: rgba(224, 234, 234, 1);
  padding: 28px;
  border-radius: 12px;
}

.billcard h3 {
  font-family: "Work Sans", sans-serif;
  font-size: 28px;
  font-weight: 500;
  color: rgba(62, 73, 73, 1);

}

.billnumber {
  height: 69px;
  width: 42px;
  font-family: "Work Sans", sans-serif;
  border: none;
  background-color: #0b9295;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  font-size: 42px;
  border-radius: 12px;
  margin-right: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billcard span {
  margin-bottom: 32px;
}

.newcontainer-fluid {
  margin-top: 30px;
}

.newcardmiddle {
  height: 599px;
}

.navbarheightwithoutTokken {
  height: unset !important;
  max-height: 216px !important;

}

@media (min-width: 767px) and (max-width: 1025px) {
  .dummy-screen-two .container-fluid {
    padding: -1px;
    overflow: hidden;
  }
}

/* for mobile view */
@media (max-width: 767px) {
  .navbarheight {
    max-height: 310px !important;
  }

  .navbarheightwithoutTokken {
    height: unset !important;
    max-height: 218px !important;

  }

  .billcard h3 {

    font-size: 20px;
  }

  .carsoual-container {
    margin-top: 0px !important;
  }

  .historyIconAnimation {
    justify-content: center !important;
  }

  .billnumber {
    height: 48px;
    width: 33px;
    padding: 10px;
    font-weight: 600;
    font-size: 25px;
    margin-right: 15px;
  }

  .billcard span {
    margin-bottom: 10px;
  }

  .billcard {


    min-height: unset;

    margin-bottom: 10px;
  }

  .newcardrating h2 {

    font-size: 22px;
  }

  .newcardrating {
    width: 100%;
  }

  /* .cardstar img {
    width: auto;
  } */

  /* .cardlike img {
    width: auto;
  } */

  .cardstar {

    padding: 4px 9px;
    border-radius: 7px;
    gap: unset;
  }

  .cardlike {

    padding: 4px 9px;
    border-radius: 7px;
    gap: unset;
  }

  .newcontainer-fluid {
    margin-top: 0px;
  }

  .cardmiddlecontent label {
    font-size: 20px !important;
  }

  .cardmiddlecontent p {
    font-size: 24px !important;
  }

  .cardmiddlecontent {

    padding: 5px 15px;
  }

  .cardmiddle {
    height: 364px;
  }

  .dummy-screen-two .newratenumber p {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  
}


/* --------------------------animation start carsoual----------- */
.slide-in-from-top.animate {
  -webkit-animation: slide-in-blurred-top 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  animation: slide-in-blurred-top 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/* .slide-in-from-top{
  -webkit-animation: slide-in-blurred-top 1.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  animation: slide-in-blurred-top 1.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
} */
/* ----------------------------------------------
 * Generated by Animista on 2024-11-21 13:49:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}



/* ---------------------------------------left animation */
.slide-in-from-left.animate {
  -webkit-animation: bounce-in-left 2s both;
  animation: bounce-in-left 2s both;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;

}



@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-2000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-2000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(30px) scaleX(1) scaleY(1);
    transform: translateX(30px) scaleX(1) scaleY(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(-10px) scaleX(1) scaleY(1);
    transform: translateX(-10px) scaleX(1) scaleY(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0) scaleX(1) scaleY(1);
    transform: translateX(0) scaleX(1) scaleY(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 1;
  }
}


/* -------------------------right animation */

.slide-in-from-right.animate {
  -webkit-animation: bounce-in-right 3.5s both;
  animation: bounce-in-right 3.5s both;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}



@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(2000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(2000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-30px) scaleX(1) scaleY(1);
    transform: translateX(-30px) scaleX(1) scaleY(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(10px) scaleX(1) scaleY(1);
    transform: translateX(10px) scaleX(1) scaleY(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0) scaleX(1) scaleY(1);
    transform: translateX(0) scaleX(1) scaleY(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 1;
  }
}



.horizonatallyanimate.animate {
  -webkit-animation: slide-out-blurred-br 2.5s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-br 2.5s cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

/* Keyframes for slide-out-blurred-br */
@-webkit-keyframes slide-out-blurred-br {
  0% {
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(100%, 100%) scale(0.9);
    transform: translate(100%, 100%) scale(0.9);
    -webkit-filter: blur(20px);
    filter: blur(20px);
    opacity: 0;
  }
}

@keyframes slide-out-blurred-br {
  0% {
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(100%, 100%) scale(0.9);
    transform: translate(100%, 100%) scale(0.9);
    -webkit-filter: blur(20px);
    filter: blur(20px);
    opacity: 0;
  }
}

.verticallyAnimate.animate {
  -webkit-animation: bounce-out-bottom 1.6s both;
  animation: bounce-out-bottom 1.6s both;

}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-21 14:59:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-out-left
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2024-11-21 15:1:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  5% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  15% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  25% {
    -webkit-transform: translateY(38px);
    transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  52% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  85% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(800px);
    transform: translateY(800px);
    opacity: 0;
  }
}

@keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  5% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  15% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  25% {
    -webkit-transform: translateY(38px);
    transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  52% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  85% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(800px);
    transform: translateY(800px);
    opacity: 0;
  }
}





/* --------------------------animation start carsoual----------- */

.recipt-image {
  width: 311.85px;


}

.newheadinone {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;

  text-align: center;



}

.recieptpara {
  color: rgba(62, 73, 73, 1);
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;

  text-align: center;





}

.learmorebuton {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.11px;

  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;


  color: rgba(11, 146, 149, 1);


}

.newupload-input {
  border: 1px solid #014178;
  border-radius: 6px;
  background: #ffffff;
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #014178;
  width: 100%;
  font-size: 16px;

  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.newsecpad {
  margin: 0;
  display: flex;
  justify-content: center;
}

.reciptupload {
  position: relative;
}

.newlogoimg {
  margin-left: 77px;
  width: 102px;
  height: 39px;
  top: 40px;
  left: 146px;
  gap: 0px;
  opacity: 0px;

}

.smiyimage {
  width: 50px;
  height: auto;
}

.smiyimageres {
  width: 20px;
  height: auto;
}

.newselected {
  background-color: rgba(264, 170, 204, 4) !important;
}

.newlikeselected {
  background-color: #5ce4e4 !important;

}



.nobutton {

  position: relative;
  display: inline-block;


  overflow: hidden;



  text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);

  transition: 0.2s;

}

.nobutton:active {
  /* border: 3px outset #ddd; */
  color: #fff;
  /* background: linear-gradient(160deg, #ffeaf0, #f3d3d3); */
  text-shadow: 0px 0px 4px #ccc;
  box-shadow: 0 0 5px #f8a5a5, 0 0 20px #f38181b2, 0 0 50px #ffa8a8;
  transition-delay: 1s;
}

.nobutton span {
  position: absolute;
  display: block;
}

.nobutton span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #f50808);
}

.nobutton:active span:nth-child(1) {
  left: 100%;
  transition: 1s;
}

.nobutton span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ff0303);
}

.nobutton:active span:nth-child(2) {
  top: 100%;
  transition: 1s;
  transition-delay: 0.25s;
}

.nobutton span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #f50808);
}

.nobutton:active span:nth-child(3) {
  right: 100%;
  transition: 1s;
  transition-delay: 0.5s;
}

.nobutton span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #f50808);
}

.nobutton:active span:nth-child(4) {
  bottom: 100%;
  transition: 1s;
  transition-delay: 0.75s;
}

/* .buttons {
  margin-top: 50px;
  text-align: center;
  border-radius: 30px;
} */

.blob-btn {
  z-index: 1;
  position: relative;
  /* padding: 20px 46px;
  margin-bottom: 30px; */
  /* text-align: center;
  text-transform: uppercase; */
  color: #000000;

  /* background-color: transparent;
  outline: none;
  border: none; */
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 10px;
}

.blob-btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* border: 2px solid #076e26; */
  border-radius: 10px;
}

.blob-btn:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  border-radius: 10px;
}

.blob-btn:hover {
  color: #FFFFFF;
  border-radius: 10px;
}

.blob-btn:hover:after {
  transition: all 0.3s;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #ffffff;
}

.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url("#goo");
}

.blob-btn__blob {
  position: absolute;
  top: 2px;
  width: 25%;
  height: 100%;
  background: #b0ddbd;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.45s;
}

@supports (filter: url("#goo")) {
  .blob-btn__blob {
    transform: translate3d(0, 150%, 0) scale(1.4);
  }
}

.blob-btn__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}

.blob-btn__blob:nth-child(2) {
  left: 30%;
  transition-delay: 0.08s;
}

.blob-btn__blob:nth-child(3) {
  left: 60%;
  transition-delay: 0.16s;
}

.blob-btn__blob:nth-child(4) {
  left: 90%;
  transition-delay: 0.24s;
}

.blob-btn:hover .blob-btn__blob {
  transform: translateZ(0) scale(1.7);
}

@supports (filter: url("#goo")) {
  .blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.4);
  }
}





.blop-btn__blob2 {
  position: absolute;
  top: 2px;
  width: 25%;
  height: 100%;
  background: rgba(11, 146, 149, 1);
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.45s;
}

@supports (filter: url("#goo")) {
  .blop-btn__blob2 {
    transform: translate3d(0, 150%, 0) scale(1.4);
  }
}

.blop-btn__blob2:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}

.blop-btn__blob2:nth-child(2) {
  left: 30%;
  transition-delay: 0.08s;
}

.blop-btn__blob2:nth-child(3) {
  left: 60%;
  transition-delay: 0.16s;
}

.blop-btn__blob2:nth-child(4) {




  left: 90%;
  transition-delay: 0.24s;
}

.blob-btn:hover .blop-btn__blob2 {
  transform: translateZ(0) scale(1.7);
}

@supports (filter: url("#goo")) {
  .blob-btn:hover .blop-btn__blob2 {
    transform: translateZ(0) scale(1.4);
  }
}

.reviewShowmobcontainer {
  height: 80vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.svg-image {
  display: none;
}

.mobcontainer {
  height: auto;
}

.pt-200 {
  padding-top: 200px;
}

.carsoual-container {
  margin-top: 2px;
}

.historyicon {
  height: 50px;
  width: 50px;
}

.historyIconAnimation {
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
  display: flex;
  justify-content: flex-end;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-19 11:39:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {

  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@keyframes wobble-hor-bottom {

  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}


/* .restaurantLeaderBoard {
  position: relative;
  height: 100vh;
  
}
  .

.leaderBoardInputField {

  transform: translate(-50%, -50%);
  width: 100%; 
  height: 100vh;
  z-index: 10;
  background: #00000078;
  text-align: center;

} */
.leaderBoardRestaurant {
  overflow-x: hidden;

}

.hamburgerlist svg {
  fill: #014178;
  width: 22px;
  height: 22px;
  display: block;
}

.hamburgerlist li {
  display: flex;
  font-family: "Work Sans", sans-serif;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #f1e7e7;
  padding: 4px 10px;
  /* padding-bottom: 9px; */
}

.reviewicon {
  width: 46px;
  height: 44px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  padding: 10px;

  border: 5px solid #007c7c;
}

.hamburgerbody {
  padding-top: 0px;
}

.review-icon {
  height: 27px;
}

.newqrimage {
  width: 161px;
  height: 100%;
}

.like-leaderboard {
  font-size: 20px !important;
}

/* 
.likeLeaderBoard {
  margin: 0 !important;
}

.leaderBoardBody {
  padding: 30px 7px !important;
}

.likeLeaderBoard .midrateno {
  padding: 16px !important;

}

.newmidrateno {
  margin: 16px 0 0 !important;

} */
.newpulse_back {

  animation: pulse 1.5s;
  -webkit-animation: pulse 1.5s;
}

.midrateno2 {
  max-height: 190px;
  overflow-y: scroll;

}

.cardmiddlecontent2 {
  border-bottom: 1px solid rgba(11, 146, 149, 0.2);
  padding: 8px 20px;
}

.cardstarsmall img {
  width: 22px;
}

.cardratingheadnew {

  padding: 8px;

}

.cardstarnew img {
  width: 24px;
}

.cardlikenew {
  padding: 4px;
  border-radius: 7px;

}

.fixwidth {
  width: 100%;
  overflow-x: hidden;
}

.mobilemenubottomnew {
  position: absolute;
  top: 14px;
  right: 15px;
}

.navbarheight {
  height: unset !important;
  max-height: 345px !important;
}

.userLeaderBoardError {
  margin-top: -10px;
  padding-left: 23px;
  font-size: 16px;
}

@media only screen and (min-device-width: 744px) and (max-device-width: 1133px) and (-webkit-min-device-pixel-ratio: 2) {

  /* Your CSS styles here */
  .newcardrating h2 {
    font-size: 19px;
  }

  .cardmiddlecontent label {
    font-size: 20px !important;
  }

  .cardmiddlecontent p {
    font-size: 22px !important;
  }

  .cardlike img {
    width: 27px;
  }

  .cardstar img {
    width: 28px;
  }
}

@media only screen and (min-width: 912px) {

  .newcardrating h2 {
    font-size: 26px;

  }

  .cardlike img {
    width: 31px;
  }

  .cardstar img {
    width: 31px;
  }

  .cardmiddlecontent label {
    font-size: 22px !important;
  }

  .cardmiddlecontent p {
    font-size: 24px !important;
  }
}




.slide-in-from-left-new1 {
  -webkit-animation: bounce-in-left .5s both;
  animation: bounce-in-left .5s both;
  border-radius: 12px 12px 0px 0px;
}

.slide-in-from-left-new2 {
  -webkit-animation: bounce-in-left 1.5s both;
  animation: bounce-in-left 1.5s both;
}

.slide-in-from-left-new3 {
  -webkit-animation: bounce-in-left 2.5s both;
  animation: bounce-in-left 2.5s both;

}
.slide-in-from-left-new4 {
  -webkit-animation: bounce-in-left 3s both;
  animation: bounce-in-left 3s both;
  border-radius: 0px 0px 12px 12px;
}



@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-2000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-2000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(30px) scaleX(1) scaleY(1);
    transform: translateX(30px) scaleX(1) scaleY(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(-10px) scaleX(1) scaleY(1);
    transform: translateX(-10px) scaleX(1) scaleY(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0) scaleX(1) scaleY(1);
    transform: translateX(0) scaleX(1) scaleY(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 1;
  }
}

.billcardnew {


  padding: 28px;


}

.restrofull .backarrow {
  position: absolute;
  top: 17px;
  left: 12px;
}

.logoimg {
  margin-left: 50px;
}

.billcardnew-title {
  background: rgba(224, 234, 234, 1);
  padding: 11px;
}

.invite-friend-header {
  background-color: #014178;
}

.invitFriendButton {
  background-color: #014178;
  color: white;
  padding: 4px;
  border-radius: 5px;
  border: none;

}

@media only screen and (max-width: 767px) {
  .newqrimage {
    width: 93px;
  }

  .newqrimagelogo {
    width: 150px;
  }

  .restimageouterdiv {
    height: 178px;
  }

  .newvidbannersec {

    padding: 9px;

  }

  .newimage-content h2 {

    font-size: 20px;
  }

  .newimage-content span {
    font-size: 14px;
  }

  .newvidbannersec {
    margin-bottom: 20px;
  }

  .newvidbannersec {
    padding: 7px;
  }

  .cardlike img {
    width: 26px;
  }

  .cardlike img {
    width: 26px;
  }

  .cardratinghead {
    padding: 7px;
  }

  .cardmiddlecontent p {
    font-size: 19px !important;
  }

  .cardmiddlecontent label {
    font-size: 16px !important;
  }

  .cardmiddlecontent p {

    line-height: unset;
  }

  .cardmiddle {
    height: 415px;
  }

  .newcardrating h2 {
    font-size: 19px;
  }

  .newimage-content p {

    line-height: 18px;
  }
}

.commonreview {
  position: fixed;
  bottom: 19px;
  cursor: grab;
  user-select: none;

  right: 23px;
  z-index: 1000;
  transition: background-color 0.3s;

}


.commonreview:active {
  cursor: grabbing;
}

.reviewlogo {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 3px solid #007c7c;
  /* border-right: none; */
  border-radius: 50%;
  /* padding: 13px; */
  /* gap: 6px; */
  height: 55px;
  width: 55px;
  justify-content: center;
}

.commonreview h3 {
  font-size: 15px;
  font-weight: 800;
  color: black;
  margin-bottom: 0px;
}

.totalcheers {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.newforminput:focus{
overflow: hidden !important;
}
@media only screen and (max-width: 767px) {
    .billcardnew-title {
  .cardratingheadnew {
    height: 55px;}
 
      padding: 12px;
    }
    .billcardnew-title h3 {
      font-size: 18px;
  }
  .mobilecardstar img{
    width: 24px;
  }
  .mobilecardlike img{
    width: 24px;
  }
  .billcardnew {


    padding: 12px;
  
  
  }
  .fixedleaderbiard{
    position: fixed;
    bottom: 10%;
    width: 94%;
  }
}

.lastTab{
  padding: unset;
}
.newRateNumber p {
  color: black !important;
  padding-right: 0;
}
.newRateNumber .userCount{
  color: #00008B !important;
}
 .usercount-center{
    width: 55px;
    text-align: start;

}
.place-order{
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin-top: 5px !important;
  
}
.rightarrow-img{
  width: 80px;
}


.banner-container{
  overflow-x: hidden;
}
.HighlightBadge{
  color: white !important;
  background-color: #007d7b;
  padding: 5px 15px;
  font-size: 15px !important;
  border-radius: 20px;
  margin-top: 12px;
  width: max-content;
}
.highest-tag-badges{
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.HighlightBadgeLeader{
  color: white !important;
  background-color: #007d7b;
  padding: 5px 15px;
  border-radius: 8px;
  width: max-content;

}
.topRatedMenu{
  color: #007d7b !important;
}
.menuHeading{
  color: #000 !important;
}


.pulse-animation {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.highlighted-menu{
  background-color: rgba(224, 234, 234, 1);
  padding: 5px 12px;
  border-radius: 8px;
  color: black !important;
  width: max-content;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dummy-screen-two .ratenumber label, .dummy-screen-two .ratenumber p {
  margin:0 !important;
}

.ReactTags__tagInputField{
  border: none;
    border-bottom: 1px solid black;
    width: 100%;
    font-size: 16px;
    padding: 9px;
}
.ReactTags__suggestions{
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
}
.ReactTags__remove {
  background: none;
  border: none; 
  cursor: pointer; 
  font-size: 18px;
  font-weight: bold;
  color: black; 
}
.ReactTags__remove svg{
  fill: #000;
}
.ReactTags__tag {
  border-radius: 20px;
  padding: 1px 11px;
  display: inline-block;
  margin: 3px;
  font-family: "Work Sans", sans-serif;
  background-color:rgb(221 221 221);
  font-size: 14px;
}
.user-point-table .table > :not(caption) > * > * {
  border-bottom: 1px dashed #d7d7d7;
  vertical-align: middle;
  padding: 10px 16px;
}
.user-point-table .table tbody td {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  /* text-transform: lowercase; */
  padding: 10px 16px;
}
.user_pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #0e1e22;
  overflow: hidden;
  margin-bottom: 0;
  margin-right: 8px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.serchbx {
  border-radius: 6px;
  background: #f4f4f5;
  width: 256px;
  height: 34px;
  flex-shrink: 0;
  padding: 8px;
}
.reward-btn{
  border: none;
  background: #dbffe7;
  color: #046b27;
  border-radius: 30px;
  padding: 5px 14px;
}

.resturant-modal-content {
  border-radius: 10px;
  margin: 0 10px;
}
.resturant-modal-content .modal-header {
padding-top: 14px;
}
.resturant-card{    
  display: flex;
      gap: 13px;
      align-items: center;}
.resturant-image img{
  height: 90px;
  width: 65px;
}
.r-item{
  border-radius: 20px;
  padding: 3px 12px;
  background-color: #dddddd4d;
}
.r-item p {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 3px;
}
.r-item .likeicon{
  width: 17px;
}
.resturant-data {
  width: 100%;
}
.resturant-data .datec{font-size: 13px;margin-bottom: 6px;}
.total-a{
  font-size: 16px;
    font-weight: 600;
    color: black;
}
.recipt-restaurant{
  font-size: 17px;
}
.citiesFilter{
  font-size: 17px;
  padding: 0 6px;
  font-weight: bold;
}
.dynamic-borderteal{
  border: 2px solid #008080 ;
}
.dynamic-borderred{
  border: 2px solid red ;
}
.number-o-n {
  color: #09b945 !important;
  font-size: 16px !important;
}
.filter-search .serchbx{border: 1px solid #c7c7c7 !important;background-color: #fff;padding: 19px 10px;border-radius: 9px;}
.filter-search .serchbx input{background: transparent !important;    border: none;
  outline: none;}
.serach-head{font-size: 20px;color: black;}
.imageclose{ position: absolute;right: 19px;}
.receipt-modal .modal-header {
  justify-content: center;
}
.receipt-modal .modal-header h2 {
font-size: 20px;
color: black;
font-weight: 600;
}
.receipt-modal .modal-header span{
  color: #8c8989;
  font-size: 14px;
  font-weight: 400;
}
.receipt-img-card{
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
  box-shadow: 0 5px 12px 0 #00000036;
}
.receipt-img-card img{
  width: 93px;
  height: 120px;
}
.receipet-images-sec {
  max-height: 326px;
  overflow-y: auto;
}
.rewardp-heading h3{font-size: 20px;}
.reward-point-section .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: black;
  box-shadow: none;
}
.accordion-button:focus {
  border-color:#dddddd ;
  outline: 0;
  box-shadow: none;
}
.accordion-item{
  border: 1px solid #dddddd;
  border-radius: 8px !important;
}
.accordion-button{padding: 11px;font-size: 14px;
  font-weight: 600;}
.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dddddd ;
}
.accordion {
  --bs-accordion-bg: #00000000 !important;}
.accordion-button::after {
width: 14px;
height: 14px;
background-size: contain;
}
.review-menu-left img{width: 40px;height: 40px;border-radius: 4px;}
.rewardprogress{    background-color: #dbdbdb;    height: 5px;  width: 100%;
}
.review-menu-right h4{font-size: 14px;font-weight: 400;}
.progress-score{font-size: 14px; font-weight: 400;}
.border-bottom{border-bottom: 1px solid #dddddd;}
.rewardmenu-content{padding: 8px 0;}
.reward-message {
  margin-top: 10px;
  border: 1px solid #05ac3e;
  padding: 5px 20px;
  border-radius: 4px;
  text-align: center;
}
.reward-message p{color: #046b27; font-size: 13px;}
.happyplateimage img{width: 100px;}
.happyplateimage h3{font-size: 26px; color: #333333;}
.happycard{padding: 15px 0;
border-radius: 20px;}
.feedback-btn{border: none;background-color: #d52954;color: #fff; font-size: 20px; border-radius: 14px; padding: 5px 16px;}
.feedback-btn span img{    width: 26px;margin-right: 4px;}
.menu-tag{border-radius: 30px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 400;white-space: nowrap;}
  .menu-tags{    max-width: 100%;
    overflow-x: auto;}
    .menu-rewards-sec h5 {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
  }
  .menu-badge{
    border: 1px solid #0b9295;
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: #0b93952b;
  }
  .css-pdct74-MuiTablePagination-selectLabel{margin-bottom: 0px !important;}
  .css-levciy-MuiTablePagination-displayedRows{margin-bottom: 0;}
  .redeem-user-id{text-decoration: underline;color: #014178 !important;font-size: 16px !important;}
  @media screen and (max-width: 768px) {
    .user-point-table .table > :not(caption) > * > * {
      white-space: nowrap;
    }
    .css-jtlhu6-MuiTablePagination-root{
      overflow: unset !important;
    }
  }
  .redeemmodal-dialog{position: unset !important;padding: 14px;}
  .redeemPoints{color: #014178;}





  .r-item img.happyPlates {
    width: 28px; 
    height: 30px;
    object-fit: contain;
  }
  
 .calcel-btn{
    border: none;
    background-color: #014178;
    color: #fff;
    border-radius: 7px;
    padding: 7px 12px;

  }
  .cancel-search {
    position: absolute;
    right: 11px;
    top: 15px;
    width: 12px;
}
.font-18 {
  font-size: 18px;
  font-weight: 600;
}
.arrow-sec{
  border-bottom: 1px solid rgba(11, 146, 149, 0.2);
  padding: 4px 32px;
}

/* curtain css */
.curtain {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.curtain__wrapper {
  width: 100%;
  height: 100%;
}

.curtain__panel {
  background: #8b0000;
  background-image: url("https://images.vexels.com/media/users/17482/101168/preview2/01bdac45c37aff22f75230abf3f019d4-red-curtain-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 100vh;
  float: left;
  position: relative;
  z-index: 2;
  transition: transform 1s ease-out;
}

.curtain__panel--left {
  transform: translateX(-100%);
}

.curtain__panel--right {
  transform: translateX(100%);
}

.curtain__prize {
  background: #333;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.curtain__prize h1 {
  color: black;
  text-align: center;
}
.curtain__panel.open {
  transform: translateX(0);
}

.curtain input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}
.curtain input[type=checkbox]:checked ~ div.curtain__panel--left {
  transform: translateX(0);
}
.curtain input[type=checkbox]:checked ~ div.curtain__panel--right {
  transform: translateX(0);
}
canvas{
  background:#000;
}
.skip-btn{
  position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 21px;
    cursor: pointer;
    border: none;
    font-size: 18px;
    font-weight: 500;
    background-color: rgb(154 2 1);
    color: #fff;
    border-radius: 6px;
}
.logocenter{
  margin: 0;
  position: absolute;
  top: 18px;
  left: 37%;
}
.progress-loading{display: flex;}
.load1 {
  border: 1px solid black;
  height: 15px;
  flex: auto;
}

.load1.active{    background-color: #e2f152;}
.newprogress-score {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: end;
  margin-top: 5px;
}
@keyframes pulse2 {
  0% { background-color: rgba(255, 215, 0, 0.5); }
  50% { background-color: rgba(255, 215, 0, 1); }
  100% { background-color: rgba(255, 215, 0, 0.5); }
  }
  
  
  
  .highlight {
  animation: pulse2 1s ease-in-out 3; 
  }


 .floating-number {
    position: fixed;
    font-size: 20px;
    font-weight: bold;
    color: blue;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    white-space: nowrap;
}

.star-firework {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: white;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    opacity: 1;
    animation: explode 1s ease-out forwards;
}

@keyframes explode {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(2); opacity: 0; }
}


  .orders_modal a{
    text-align: left;
    justify-content: flex-start;
    margin: 0px;
    margin-bottom: 10px;
    padding: 10px;
    height: auto;
  }

  .orders_modal p{
    font-size: 16px;
    font-weight: 600;
    padding-left: 15px;
  }
  .next-after-active {
    background: linear-gradient(90deg, hsl(0, 0%, 90%) 50%, #ffffff 50%);
    animation: fadeGradient 1.5s infinite alternate;
}

@keyframes fadeGradient {
    0% {
        background: linear-gradient(90deg, hsl(0, 0%, 90%) 50%, #ffffff 50%);
    }
    100% {
        background: linear-gradient(90deg, hsl(0, 0%, 100%) 50%, #ffffff 50%);
    }
}
  
  .font-14{font-size: 14px;
    font-weight: 600;}
    .lll{width: 33px; display: inline-block;}
    .numbering_order{
      position: absolute;
      font-weight: 800;
      left: 10px;
    }

    .counted_review {
      width: 21px;
      display: inline-block;
      text-align: center;
  }
  .resturenleaderboadclss{
    max-height: 250px;
  }

  .engage-tabs .nav {
    border-bottom: 1px solid #014178;
  }
  .engage-tabs .nav .nav-item .nav-link {
    color: #014178;
    border-bottom: 3px solid transparent;
    border-radius: 0px;
    padding: 0px 12px 15px;
    margin-right: 10px;
    font-weight: 500;
  }
  .engage-tabs .nav .nav-item .nav-link.active {
    border-bottom: 3px solid #014178;
    background-color: transparent;
    color: #014178;
  }
  
  @media (max-width: 767px) {
    .engage-tabs .nav {
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
    }
}
  